import * as React from 'react';
import { range } from 'ramda';
import { i18n } from '@goldwasserexchange/i18n';
import * as data from '../../data';
var labels = {
    bondDefinitionAnswer: {
        ID: 'bondDefinitionAnswer',
        label: function () { return i18n._('Vous détenez une obligation. Quelle affirmation reprise ci-dessous est correcte ?'); },
    },
    bondVariationAnswer: {
        ID: 'bondVariationAnswer',
        label: function () { return i18n._('Le cours (prix) d\'une obligation peut-il augmenter ou baisser suite aux évolutions des marchés ?'); },
    },
    bondDefaultAnswer: {
        ID: 'bondDefaultAnswer',
        label: function () { return i18n._('Si l\'émetteur d\'une obligation tombe en faillite, pouvez-vous toujours récupérer le capital que vous avez investi ?'); },
    },
    bondSaleAnswer: {
        ID: 'bondSaleAnswer',
        label: function () { return i18n._('Pouvez-vous vendre votre obligation à tout moment ?'); },
    },
    bondCurrencyRiskAnswer: {
        ID: 'bondCurrencyRiskAnswer',
        label: function () { return i18n._('Vous achetez une obligation libellée en devise étrangère (par exemple en USD). Courez-vous un risque de change ?'); },
    },
    stockDefinitionAnswer: {
        ID: 'stockDefinitionAnswer',
        label: function () { return i18n._('Vous détenez une action. Quelle affirmation reprise ci-dessous est correcte ?'); },
    },
    stockVariationAnswer: {
        ID: 'stockVariationAnswer',
        label: function () { return i18n._('Le cours d\'une action peut-il augmenter ou baisser en raison de l\'évolution des marchés ?'); },
    },
    stockDefaultAnswer: {
        ID: 'stockDefaultAnswer',
        label: function () { return i18n._('Si l\'entreprise dont vous détenez une action tombe en faillite, pouvez-vous toujours récupérer le capital que vous avez investi ?'); },
    },
    stockSaleAnswer: {
        ID: 'stockSaleAnswer',
        label: function () { return i18n._('Pouvez-vous vendre votre action à tout moment ?'); },
    },
    stockCurrencyRiskAnswer: {
        ID: 'stockCurrencyRiskAnswer',
        label: function () { return i18n._('Vous achetez une action libellée en devise étrangère (par exemple en USD). Courez-vous un risque de change ?'); },
    },
    fundDefinitionAnswer: {
        ID: 'fundDefinitionAnswer',
        label: function () { return i18n._('Vous détenez une action ou une part d\'un fonds. Quelle affirmation reprise ci-dessous est correcte ?'); },
    },
    fundVariationAnswer: {
        ID: 'fundVariationAnswer',
        label: function () { return i18n._('Le cours des actions ou parts d\'un fonds peut-il augmenter ou baisser suite aux évolutions des marchés ?'); },
    },
    fundSaleAnswer: {
        ID: 'fundSaleAnswer',
        label: function () { return i18n._('Pouvez-vous vendre les actions ou parts de votre fonds à tout moment ?'); },
    },
    fundDefaultAnswer: {
        ID: 'fundDefaultAnswer',
        label: function () { return i18n._('Etes-vous certain de récupérer le capital que vous avez investi ?'); },
    },
    fundCurrencyRiskAnswer: {
        ID: 'fundCurrencyRiskAnswer',
        label: function () { return i18n._('Vous achetez les actions ou parts d\'un fonds libellées en devise étrangère (par exemple en USD). Courez-vous un risque de change ?'); },
    },
    fundNetAssetValueAnswer: {
        ID: 'fundNetAssetValueAnswer',
        label: function () { return i18n._('Qu\'est ce que la valeur nette d\'inventaire d\'un fonds ?'); },
    },
    perpetualBondDefinitionAnswer: {
        ID: 'perpetualBondDefinitionAnswer',
        label: function () { return i18n._('Quel est le risque principal d\'une obligation perpétuelle ?'); },
    },
    perpetualBondVariationAnswer: {
        ID: 'perpetualBondVariationAnswer',
        label: function () { return i18n._('Le cours (prix) d\'une obligation perpétuelle est-il plus sensible à l\'évolution des marchés financiers que celui d\'une obligation classique ?'); },
    },
    perpetualBondCallAnswer: {
        ID: 'perpetualBondCallAnswer',
        label: function () { return i18n._('L\'émetteur d\'une obligation perpétuelle a-t-il le droit de la rembourser ?'); },
    },
    subordinatedBondDefinitionAnswer: {
        ID: 'subordinatedBondDefinitionAnswer',
        label: function () { return i18n._('Que signifie subordonnée ?'); },
    },
    subordinatedBondVariationAnswer: {
        ID: 'subordinatedBondVariationAnswer',
        label: function () { return i18n._('Le cours (prix) d\'une obligation subordonnée est-il plus sensible à l\'évolution des marchés financiers que celui d\'une obligation classique ?'); },
    },
    trackerAndETFDefinitionAnswer: {
        ID: 'trackerAndETFDefinitionAnswer',
        label: function () { return i18n._('Qu\'est-ce qu\'un tracker ?'); },
    },
    trackerAndETFSyntheticAnswer: {
        ID: 'trackerAndETFSyntheticAnswer',
        label: function () { return i18n._('Qu\'est-ce qu\'un tracker synthétique ?'); },
    },
    trackerAndETFSyntheticRiskAnswer: {
        ID: 'trackerAndETFSyntheticRiskAnswer',
        label: function () { return i18n._('Un tracker synthétique présente un certain risque. Quelle affirmation est correcte ?'); },
    },
    trackerAndETFSaleAnswer: {
        ID: 'trackerAndETFSaleAnswer',
        label: function () { return i18n._('Pouvez-vous vendre un tracker à tout moment ?'); },
    },
    trackerAndETFCurrencyRiskAnswer: {
        ID: 'trackerAndETFCurrencyRiskAnswer',
        label: function () { return i18n._('Vous achetez un tracker libellé en devise étrangère (par exemple en USD). Courez-vous un risque de change ?'); },
    },
    rawMaterialTrackerRiskAnswer: {
        ID: 'rawMaterialTrackerRiskAnswer',
        label: function () { return i18n._('Quel est le risque principal que vous courez lorsque vous investissez dans un tracker sur matières premières ?'); },
    },
    rawMaterialTrackerRiskCauseAnswer: {
        ID: 'rawMaterialTrackerRiskCauseAnswer',
        label: function () { return i18n._('Pourquoi dit-on que les trackers sur matières premières sont des produits particulièrement risqués ?'); },
    },
    RTOBondQuestion0Answer: {
        ID: 'RTOBondQuestion0Answer',
        label: function () { return i18n._('Si vous achetez une obligation d\'entreprise, vous :'); },
    },
    RTOBondQuestion1Answer: {
        ID: 'RTOBondQuestion1Answer',
        label: function () { return i18n._('Par rapport à d\'autres placements dans la même devise, une obligation offrant un niveau relativement élevé de rendement est forcément un investissement à risque ?'); },
    },
    RTOBondQuestion2Answer: {
        ID: 'RTOBondQuestion2Answer',
        label: function () { return i18n._('Est-il possible de sortir d\'une obligation avant la date prévue pour son remboursement ?'); },
    },
    RTOBondQuestion3Answer: {
        ID: 'RTOBondQuestion3Answer',
        label: function () { return i18n._('Sur le marché secondaire, le prix d\'une obligation va généralement :'); },
    },
    RTOBondQuestion4Answer: {
        ID: 'RTOBondQuestion4Answer',
        label: function () { return i18n._('Un investisseur dont l\'objectif premier est d\'assurer une récupération intégrale du capital investi aura généralement intérêt à privilégier :'); },
    },
    RTOBondQuestion5Answer: {
        ID: 'RTOBondQuestion5Answer',
        label: function () { return i18n._('Que faut-il penser d\'une obligation ne disposant d\'aucun rating ?'); },
    },
    RTOBondQuestion6Answer: {
        ID: 'RTOBondQuestion6Answer',
        label: function () { return i18n._('Les taux d\'intérêt et le prix des obligations :'); },
    },
    RTOBondQuestion7Answer: {
        ID: 'RTOBondQuestion7Answer',
        label: function () { return i18n._('En tant qu\'investisseur dont la monnaie de référence est l\'euro, vous achetez une obligation libellée en devise étrangère. Cela implique-t-il un risque supplémentaire de ne pas récupérer l\'intégralité du capital investi ?'); },
    },
    RTOBondQuestion8Answer: {
        ID: 'RTOBondQuestion8Answer',
        label: function () { return i18n._('Vous habitez en Belgique et détenez une obligation libellée en USD. En début d\'année 1 EUR vaut 1,10 USD. En fin d\'année 1 EUR vaut 1,25 USD. Cette évolution est-elle :'); },
    },
    RTOBondQuestion9Answer: {
        ID: 'RTOBondQuestion9Answer',
        label: function () { return i18n._('Il n\'est généralement pas recommandé d\'investir dans une émission de petite taille si:'); },
    },
    RTOBondQuestion10Answer: {
        ID: 'RTOBondQuestion10Answer',
        label: function () { return i18n._('Vous achetez une obligation à 104% du prix d\'émission avec un coupon de 3%. Le prospectus d\'émission prévoit la possibilité pour l\'émetteur de procéder deux mois plus tard au remboursement anticipatif de l\'obligation pour 100% du prix d\'émission. Si un tel remboursement intervient vous aurez:'); },
    },
    RTOBondQuestion11Answer: {
        ID: 'RTOBondQuestion11Answer',
        label: function () { return i18n._('Le fait que la dénomination d\'une obligation contienne le nom d\'une entreprise bien connue signifie-t-il toujours que cette entreprise garantit cette émission ?'); },
    },
    RTOBondQuestion0RemedialAnswer: {
        ID: 'RTOBondQuestion0RemedialAnswer',
        label: function () { return i18n._('Pour emprunter de l\'argent sur les marchés financiers, une entreprise peut émettre :'); },
    },
    RTOBondQuestion1RemedialAnswer: {
        ID: 'RTOBondQuestion1RemedialAnswer',
        label: function () { return i18n._('Un investisseur a le choix entre deux obligations en Euro dont la date d\'échéance est identique. L\'une offre un rendement de 2%, l\'autre un rendement de 4%. Laquelle devra-t-il sélectionner s\'il souhaite privilégier des investissements défensifs ?'); },
    },
    RTOBondQuestion2RemedialAnswer: {
        ID: 'RTOBondQuestion2RemedialAnswer',
        label: function () { return i18n._('Si j\'investis dans une obligation sans être sûr de pouvoir la conserver jusqu\'à l\'échéance, je risque :'); },
    },
    RTOBondQuestion3RemedialAnswer: {
        ID: 'RTOBondQuestion3RemedialAnswer',
        label: function () { return i18n._('Dans un contexte où les taux d\'intérêts sont stables, la diminution brutale du prix d\'une obligation peut signifier que :'); },
    },
    RTOBondQuestion4RemedialAnswer: {
        ID: 'RTOBondQuestion4RemedialAnswer',
        label: function () { return i18n._('Si l\'entreprise Alpha émet une obligation avec un rating BB+ et l\'entreprise Delta une obligation avec un rating BBB, on peut généralement dire que :'); },
    },
    RTOBondQuestion5RemedialAnswer: {
        ID: 'RTOBondQuestion5RemedialAnswer',
        label: function () { return i18n._('Une obligation dont le rating auprès de l\'agence S&P est de B+ doit être considérée comme:'); },
    },
    RTOBondQuestion6RemedialAnswer: {
        ID: 'RTOBondQuestion6RemedialAnswer',
        label: function () { return i18n._('Si le rendement des obligations allemandes à 10 ans passe de 3 à 4% laquelle de ces obligations perdra généralement le plus de valeur :'); },
    },
    RTOBondQuestion7RemedialAnswer: {
        ID: 'RTOBondQuestion7RemedialAnswer',
        label: function () { return i18n._('Dans quelle devise un investisseur new-yorkais doit-il investir pour éviter le risque de change ?'); },
    },
    RTOBondQuestion8RemedialAnswer: {
        ID: 'RTOBondQuestion8RemedialAnswer',
        label: function () { return i18n._('Si vous habitez en Espagne et que vous avez acheté en fin d\'année une obligation libellée en USD à un taux de 1 EUR = 1,05 USD, quel taux de change sera le plus favorable si vous désirez revendre votre obligation au milieu de l\'année suivante'); },
    },
    RTOBondQuestion9RemedialAnswer: {
        ID: 'RTOBondQuestion9RemedialAnswer',
        label: function () { return i18n._('Quand peut-on dire d\'une obligation qu\'elle est peu liquide ?'); },
    },
    RTOBondQuestion10RemedialAnswer: {
        ID: 'RTOBondQuestion10RemedialAnswer',
        label: function () { return i18n._('Avant d\'acheter une obligation est-il important de vérifier si l\'émetteur dispose d\'une possibilité de rembourser anticipativement son obligation (call) ?'); },
    },
    RTOBondQuestion11RemedialAnswer: {
        ID: 'RTOBondQuestion11RemedialAnswer',
        label: function () { return i18n._('Un investisseur accorde suffisamment de crédit à l\'entreprise « ABC » pour lui prêter de l\'argent. Par conséquent, il envisage d\'investir dans une obligation dont le libellé est « ABC Bondco ». A quoi doit-il prêter une attention toute particulière :'); },
    },
    RTOPerpetualBondQuestion0Answer: {
        ID: 'RTOPerpetualBondQuestion0Answer',
        label: function () { return i18n._('Quand peut-on être certain de récupérer le capital investi dans une obligation perpétuelle ?'); },
    },
    RTOPerpetualBondQuestion0RemedialAnswer: {
        ID: 'RTOPerpetualBondQuestion0RemedialAnswer',
        label: function () { return i18n._('Quelle est la caractéristique principale d\'une obligation perpétuelle ?'); },
    },
    RTOPerpetualBondQuestion1Answer: {
        ID: 'RTOPerpetualBondQuestion1Answer',
        label: function () { return i18n._('Par rapport aux obligations ordinaires, les obligations perpétuelles présentent d\'une manière générale:'); },
    },
    RTOPerpetualBondQuestion1RemedialAnswer: {
        ID: 'RTOPerpetualBondQuestion1RemedialAnswer',
        label: function () { return i18n._('Pour quelles raisons une obligation perpétuelle présente-t-elle plus de risques qu\'une obligation ordinaire ?'); },
    },
    RTOPerpetualBondQuestion2Answer: {
        ID: 'RTOPerpetualBondQuestion2Answer',
        label: function () { return i18n._('Qu\'est-ce qu\'une obligation à coupon non-cumulatif ?'); },
    },
    RTOPerpetualBondQuestion2RemedialAnswer: {
        ID: 'RTOPerpetualBondQuestion2RemedialAnswer',
        label: function () { return i18n._('Quel est le risque pour un investisseur d\'acheter une obligation à coupon non-cumulatif ?'); },
    },
    RTOSubordinatedBondQuestion0Answer: {
        ID: 'RTOSubordinatedBondQuestion0Answer',
        label: function () { return i18n._('Qu\'est-ce qu\'une obligation subordonnée ?'); },
    },
    RTOSubordinatedBondQuestion0RemedialAnswer: {
        ID: 'RTOSubordinatedBondQuestion0RemedialAnswer',
        label: function () { return i18n._('En cas de faillite d\'une société, déterminez l\'ordre de remboursement des investisseurs mentionnés'); },
    },
    RTOSubordinatedBondQuestion1Answer: {
        ID: 'RTOSubordinatedBondQuestion1Answer',
        label: function () { return i18n._('En cas de faillite d\'une société, déterminez lequel de ces créanciers sera généralement remboursé en dernier'); },
    },
    RTOSubordinatedBondQuestion1RemedialAnswer: {
        ID: 'RTOSubordinatedBondQuestion1RemedialAnswer',
        label: function () { return i18n._('La société QUICK vient d\'émettre plusieurs obligations en euro avec une même date d\'échéance. Laquelle aurez-vous tendance à préférer si vous souhaitez limiter le risque ?'); },
    },
    RTORawMaterialTrackerQuestion0Answer: {
        ID: 'RTORawMaterialTrackerQuestion0Answer',
        label: function () { return i18n._('Quelle est la caractéristique principale d\'un tracker ?'); },
    },
    RTORawMaterialTrackerQuestion0RemedialAnswer: {
        ID: 'RTORawMaterialTrackerQuestion0RemedialAnswer',
        label: function () { return i18n._('Un instrument de placement dont la stratégie d\'investissement consiste à répliquer fidèlement l\'évolution d\'un indice financier tel que par exemple le Bel20 est :'); },
    },
    RTORawMaterialTrackerQuestion1Answer: {
        ID: 'RTORawMaterialTrackerQuestion1Answer',
        label: function () { return i18n._('Pouvez-vous vendre un tracker à tout moment ?'); },
    },
    RTORawMaterialTrackerQuestion1RemedialAnswer: {
        ID: 'RTORawMaterialTrackerQuestion1RemedialAnswer',
        label: function () { return i18n._('Sur quelle plateforme d\'échange se traitent généralement les trackers :'); },
    },
    RTORawMaterialTrackerQuestion2Answer: {
        ID: 'RTORawMaterialTrackerQuestion2Answer',
        label: function () { return i18n._('Un tracker investit-il toujours dans le ou les actifs qui composent son indice de référence :'); },
    },
    RTORawMaterialTrackerQuestion2RemedialAnswer: {
        ID: 'RTORawMaterialTrackerQuestion2RemedialAnswer',
        label: function () { return i18n._('Qu\'est-ce qu\'un tracker synthétique ?'); },
    },
    RTORawMaterialTrackerQuestion3Answer: {
        ID: 'RTORawMaterialTrackerQuestion3Answer',
        label: function () { return i18n._('Est-il vrai que les trackers synthétiques présentent un risque supplémentaire par rapport aux trackers qui investissent directement dans les actifs qui composent leur indice de référence ?'); },
    },
    RTORawMaterialTrackerQuestion3RemedialAnswer: {
        ID: 'RTORawMaterialTrackerQuestion3RemedialAnswer',
        label: function () { return i18n._('Dans lequel de ces trackers le risque de contrepartie est-il plus important ?'); },
    },
    RTORawMaterialTrackerQuestion4Answer: {
        ID: 'RTORawMaterialTrackerQuestion4Answer',
        label: function () { return i18n._('Quel est le risque principal que vous courez lorsque vous investissez dans un tracker sur matières premières ?'); },
    },
    RTORawMaterialTrackerQuestion4RemedialAnswer: {
        ID: 'RTORawMaterialTrackerQuestion4RemedialAnswer',
        label: function () { return i18n._('Si le prix de l\'or diminue significativement comment réagira le cours d\'un tracker sur l\'or ?'); },
    },
    RTORawMaterialTrackerQuestion5Answer: {
        ID: 'RTORawMaterialTrackerQuestion5Answer',
        label: function () { return i18n._('Pourquoi dit-on que les trackers sur matières premières sont des produits particulièrement risqués ?'); },
    },
    RTORawMaterialTrackerQuestion5RemedialAnswer: {
        ID: 'RTORawMaterialTrackerQuestion5RemedialAnswer',
        label: function () { return i18n._('Un tracker sur l\'or et est-il plus ou moins spéculatif qu\'un tracker sur les bitcoins ?'); },
    },
};
export var useQuestionLabel = function (questionId) {
    var _a, _b;
    var translated = (_b = (_a = labels[questionId]) === null || _a === void 0 ? void 0 : _a.label()) !== null && _b !== void 0 ? _b : '';
    return translated;
};
export var QuestionLabel = function (props) {
    var questionId = props.questionId;
    var translated = useQuestionLabel(questionId);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
export var potentialAnswerIsQuestionId = function (potentialAnswer, questionId) { return potentialAnswer.questions.includes(questionId); };
export var getPotentialAnswerIdsByQuestionId = function (potentialAnswers, questionId) { return Object.keys(potentialAnswers)
    .filter(function (potentialAnswersId) { return questionId && potentialAnswerIsQuestionId(potentialAnswers[potentialAnswersId], questionId); }); };
export var getPotentialAnswerPrimaryKeysByQuestionId = function (questionId) {
    var potentialAnswerLength = getPotentialAnswerIdsByQuestionId(data.questionnairePotentialAnswers, questionId).length;
    return range(0, potentialAnswerLength);
};
export var getPotentialAnswerPrimaryKeysByQuestionIdFlatList = function (questionId) { return getPotentialAnswerPrimaryKeysByQuestionId(questionId)
    .map(function (id) { return "".concat(id); }); };
export var getPotentialAnswerPrimaryKeysByQuestionIdObjectList = function (questionId) { return getPotentialAnswerPrimaryKeysByQuestionId(questionId)
    .map(function (id) { return ({ ID: "".concat(id) }); }); };
export var getPotentialAnswerIdByQuestionIdAndAnswerIndex = function (potentialAnswers, questionId, answerIndex) {
    var result = Object.keys(potentialAnswers)
        .find(function (potentialAnswersId) { return questionId && potentialAnswerIsQuestionId(potentialAnswers[potentialAnswersId], questionId) && potentialAnswers[potentialAnswersId].answerIndex === answerIndex; });
    return result !== null && result !== void 0 ? result : '';
};
export var answerLabels = {
    0: {
        answerIndex: 3,
        label: function () { return i18n._('Je ne sais pas'); },
    },
    1: {
        answerIndex: 0,
        label: function () { return i18n._('Je suis copropriétaire de l\'émetteur de l\'obligation'); },
    },
    2: {
        answerIndex: 1,
        label: function () { return i18n._('J\'ai une dette vis-à-vis de l\'émetteur de l\'obligation'); },
    },
    3: {
        answerIndex: 2,
        label: function () { return i18n._('Je suis créancier. Je prête de l\'argent à l\'émetteur de l\'obligation'); },
    },
    4: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    5: {
        answerIndex: 1,
        label: function () { return i18n._('Le prix est susceptible d\'augmenter mais pas de baisser'); },
    },
    6: {
        answerIndex: 2,
        label: function () { return i18n._('Non'); },
    },
    8: {
        answerIndex: 0,
        label: function () { return i18n._('En principe oui, mais il est possible que je doive vendre à un cours inférieur au capital investi'); },
    },
    9: {
        answerIndex: 1,
        label: function () { return i18n._('Je peux à tout moment vendre mon obligation au prix d\'émission'); },
    },
    10: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je dois toujours garder mon obligation jusqu\'à l\'échéance'); },
    },
    12: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, je récupère toujours mon capital investi'); },
    },
    13: {
        answerIndex: 1,
        label: function () { return i18n._('Oui, à condition qu\'il ne s\'agisse pas d\'une obligation subordonnée'); },
    },
    14: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je cours le risque de perdre tout ou partie de mon capital investi'); },
    },
    15: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, la valeur de la devise dans laquelle j\'ai investi peut augmenter ou baisser par rapport à l\'euro'); },
    },
    16: {
        answerIndex: 1,
        label: function () { return i18n._('Cela dépend de la devise dans laquelle est libellée l\'obligation'); },
    },
    17: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je ne suis pas exposé aux fluctuations de valeur de la devise étrangère'); },
    },
    18: {
        answerIndex: 0,
        label: function () { return i18n._('Je suis copropriétaire de l\'entreprise émettrice de l\'action'); },
    },
    19: {
        answerIndex: 1,
        label: function () { return i18n._('J\'ai une dette vis-à-vis de l\'émetteur de l\'action'); },
    },
    20: {
        answerIndex: 2,
        label: function () { return i18n._('Je suis créancier. Je prête de l\'argent à l\'entreprise émettrice de l\'action'); },
    },
    21: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    22: {
        answerIndex: 1,
        label: function () { return i18n._('Le prix est susceptible d\'augmenter mais pas de baisser'); },
    },
    23: {
        answerIndex: 2,
        label: function () { return i18n._('Non'); },
    },
    24: {
        answerIndex: 0,
        label: function () { return i18n._('En principe oui, mais il est possible que je doive vendre à un cours inférieur au capital investi'); },
    },
    25: {
        answerIndex: 1,
        label: function () { return i18n._('Oui, à tout moment pour un prix au moins équivalent à mon prix d\'achat'); },
    },
    26: {
        answerIndex: 2,
        label: function () { return i18n._('Non, il est impossible de vendre une action à court terme'); },
    },
    27: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, je récupère toujours mon capital investi'); },
    },
    28: {
        answerIndex: 1,
        label: function () { return i18n._('Oui, il suffit d\'attendre que les conditions de marché s\'améliorent'); },
    },
    29: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je cours le risque de perdre tout ou partie de mon capital investi'); },
    },
    30: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, la valeur de la devise dans laquelle j\'ai investi peut augmenter ou baisser par rapport à l\'euro'); },
    },
    31: {
        answerIndex: 1,
        label: function () { return i18n._('Cela dépend de la devise dans laquelle est libellée l\'action'); },
    },
    32: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je ne suis pas exposé aux fluctuations de valeur de la devise étrangère'); },
    },
    33: {
        answerIndex: 0,
        label: function () { return i18n._('Mon argent est mis en commun avec l\'argent d\'autres personnes en vue d\'être investi selon une stratégie d\'investissement préétablie'); },
    },
    34: {
        answerIndex: 1,
        label: function () { return i18n._('Mon argent est mis en commun avec l\'argent d\'autres personnes en vue d\'être investi dans un produit financier particulier'); },
    },
    35: {
        answerIndex: 2,
        label: function () { return i18n._('Je prête de l\'argent à l\'émetteur des actions ou parts du fonds. Je suis donc créancier'); },
    },
    36: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    37: {
        answerIndex: 1,
        label: function () { return i18n._('Le prix est susceptible d\'augmenter mais pas de baisser'); },
    },
    38: {
        answerIndex: 2,
        label: function () { return i18n._('Non'); },
    },
    39: {
        answerIndex: 0,
        label: function () { return i18n._('En principe, je peux vendre quotidiennement mais des exceptions sont possibles'); },
    },
    40: {
        answerIndex: 1,
        label: function () { return i18n._('Pour des raisons fiscales, il vaut mieux rester investi au moins 8 ans'); },
    },
    41: {
        answerIndex: 2,
        label: function () { return i18n._('Non, mon argent doit rester investi pendant un certain nombre d\'années'); },
    },
    42: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    43: {
        answerIndex: 1,
        label: function () { return i18n._('Oui, il suffit d\'attendre que les conditions de marché s\'améliorent'); },
    },
    44: {
        answerIndex: 2,
        label: function () { return i18n._('Non'); },
    },
    45: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, la valeur de la devise dans laquelle j\'ai investi peut augmenter ou baisser par rapport à l\'euro'); },
    },
    46: {
        answerIndex: 1,
        label: function () { return i18n._('Cela dépend de la devise dans laquelle est libellée l\'action'); },
    },
    47: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je ne suis pas exposé aux fluctuations de valeur de la devise étrangère'); },
    },
    48: {
        answerIndex: 0,
        label: function () { return i18n._('Il s\'agit du prix auquel les parts d\'un fonds peuvent être achetées ou vendues à un moment donné'); },
    },
    49: {
        answerIndex: 1,
        label: function () { return i18n._('Il s\'agit du taux auquel les revenus de votre investissement sera taxé'); },
    },
    50: {
        answerIndex: 2,
        label: function () { return i18n._('Il s\'agit du montant des revenus générés par un fonds'); },
    },
    51: {
        answerIndex: 0,
        label: function () { return i18n._('Le fait que cette obligation soit perpétuelle signifie que je ne peux pas la vendre'); },
    },
    52: {
        answerIndex: 1,
        label: function () { return i18n._('Le risque d\'une obligation perpétuelle est exactement le même que celui d\'une obligation classique'); },
    },
    53: {
        answerIndex: 2,
        label: function () { return i18n._('Le risque d\'une obligation perpétuelle se rapproche plus de celui d\'une action que de celui d\'une obligation'); },
    },
    54: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, la volatilité du cours est plus importante'); },
    },
    55: {
        answerIndex: 1,
        label: function () { return i18n._('Cela importe peu car, à l\'échéance, je serai remboursé'); },
    },
    56: {
        answerIndex: 2,
        label: function () { return i18n._('Non, cette obligation suit la même évolution que celle d\'une obligation classique'); },
    },
    57: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, les émetteurs de telles obligations se réservent généralement la possibilité de rembourser l\'obligation à des dates et conditions prédéterminées'); },
    },
    58: {
        answerIndex: 1,
        label: function () { return i18n._('Cela dépend du pays d\'émission'); },
    },
    59: {
        answerIndex: 2,
        label: function () { return i18n._('Non, il est exclu que l\'émetteur de l\'obligation puisse y mettre un terme'); },
    },
    60: {
        answerIndex: 0,
        label: function () { return i18n._('En cas de faillite de l\'émetteur, je ne peux prétendre qu\'au montant qui subsiste après le remboursement des créanciers ordinaires (dont les détenteurs d\'obligations classiques, non subordonnées)'); },
    },
    61: {
        answerIndex: 1,
        label: function () { return i18n._('En cas de faillite de l\'émetteur, les détenteurs d\'obligations subordonnées sont remboursés avant les détenteurs d\'obligations classiques'); },
    },
    62: {
        answerIndex: 2,
        label: function () { return i18n._('Je ne peux pas bénéficier de la réglementation en matière de protection de l\'investisseur'); },
    },
    63: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    64: {
        answerIndex: 1,
        label: function () { return i18n._('Cela dépend de la devise dans laquelle est libellée l\'obligation'); },
    },
    65: {
        answerIndex: 2,
        label: function () { return i18n._('Non'); },
    },
    66: {
        answerIndex: 0,
        label: function () { return i18n._('Un tracker est un fonds que vous pouvez négocier en bourse. Ce fonds suit les mouvements d\'un indice déterminé ou d\'un panier déterminé d\'actions ou d\'obligations'); },
    },
    67: {
        answerIndex: 1,
        label: function () { return i18n._('Un tracker est un fonds non réglementé qui suit une stratégie alternative et qui n\'est pas coté en bourse'); },
    },
    68: {
        answerIndex: 2,
        label: function () { return i18n._('Il s\'agit d\'options cotées en bourse'); },
    },
    69: {
        answerIndex: 0,
        label: function () { return i18n._('Un tracker synthétique investit directement dans les instruments financiers ou l\'indice de référence dont il entend répliquer la performance'); },
    },
    70: {
        answerIndex: 1,
        label: function () { return i18n._('Un tracker synthétique n\'investit pas lui-même dans les instruments financiers ou l\'indice de référence dont il entend répliquer la performance mais conclut un accord avec une contrepartie afin d\'en reproduire le rendement'); },
    },
    71: {
        answerIndex: 2,
        label: function () { return i18n._('Il s\'agit d\'un tracker qui n\'investit que dans des obligations convertibles'); },
    },
    72: {
        answerIndex: 0,
        label: function () { return i18n._('Je cours uniquement le risque lié aux valeurs dont le tracker entend répliquer la performance'); },
    },
    73: {
        answerIndex: 1,
        label: function () { return i18n._('Je ne cours qu\'un risque théorique très faible'); },
    },
    74: {
        answerIndex: 2,
        label: function () { return i18n._('Je cours un risque complémentaire de perte du capital investi si la contrepartie ne peut pas respecter ses obligations (risque de contrepartie)'); },
    },
    75: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, mais il est possible que je doive vendre à un cours inférieur au capital investi'); },
    },
    76: {
        answerIndex: 1,
        label: function () { return i18n._('Pour des raisons fiscales, il vaut mieux rester investi au moins 8 ans'); },
    },
    77: {
        answerIndex: 2,
        label: function () { return i18n._('Non, il est impossible de vendre un tracker à court terme'); },
    },
    78: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, car un tracker suit de près l\'indice boursier et engendre généralement moins de coûts qu\'un fonds classique'); },
    },
    79: {
        answerIndex: 1,
        label: function () { return i18n._('Non, car les trackers sont plus lourdement taxés'); },
    },
    80: {
        answerIndex: 2,
        label: function () { return i18n._('Non, car le gestionnaire d\'un fonds classique peut dévier de l\'indice boursier et dès lors atteindre un rendement supérieur'); },
    },
    81: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, la valeur de la devise dans laquelle j\'ai investi peut augmenter ou baisser par rapport à l\'euro'); },
    },
    82: {
        answerIndex: 1,
        label: function () { return i18n._('Non, il me suffit d\'attendre que le taux de change redevienne favorable'); },
    },
    83: {
        answerIndex: 2,
        label: function () { return i18n._('Non, je ne suis pas exposé aux fluctuations de valeur de la devise étrangère'); },
    },
    84: {
        answerIndex: 0,
        label: function () { return i18n._('Le risque principal est lié à la santé financière de l\'émetteur'); },
    },
    85: {
        answerIndex: 1,
        label: function () { return i18n._('Le risque principal est celui lié à l\'évolution du prix des matières premières sur lesquelles portent le tracker'); },
    },
    86: {
        answerIndex: 2,
        label: function () { return i18n._('Le risque principal est lié à la solvabilité des entreprises actives dans le secteur des matières premières'); },
    },
    87: {
        answerIndex: 0,
        label: function () { return i18n._('Car le prix des matières premières est particulièrement volatil'); },
    },
    88: {
        answerIndex: 1,
        label: function () { return i18n._('Parce qu\'ils sont émis par des sociétés fortement endettées'); },
    },
    89: {
        answerIndex: 2,
        label: function () { return i18n._('Parce qu\'il s\'agit de produit dans lequel l\'investisseur est bloqué pendant plusieurs années'); },
    },
    90: {
        answerIndex: 0,
        label: function () { return i18n._('Devenez propriétaire d\'une partie de l\'entreprise'); },
    },
    91: {
        answerIndex: 1,
        label: function () { return i18n._('Devenez créancier de l\'entreprise'); },
    },
    92: {
        answerIndex: 2,
        label: function () { return i18n._('Ne devenez ni créancier, ni propriétaire de l\'entreprise'); },
    },
    93: {
        answerIndex: 0,
        label: function () { return i18n._('Vrai'); },
    },
    94: {
        answerIndex: 1,
        label: function () { return i18n._('Faux'); },
    },
    95: {
        answerIndex: 2,
        label: function () { return i18n._('Cela dépend de la conjoncture économique'); },
    },
    96: {
        answerIndex: 0,
        label: function () { return i18n._('Non, il faut obligatoirement attendre l\'échéance'); },
    },
    97: {
        answerIndex: 1,
        label: function () { return i18n._('Oui, il est possible de récupérer la somme investie à tout moment'); },
    },
    98: {
        answerIndex: 2,
        label: function () { return i18n._('Généralement oui, mais dans ce cas, rien ne garantit une récupération intégrale du capital investi'); },
    },
    99: {
        answerIndex: 0,
        label: function () { return i18n._('Rester stable'); },
    },
    100: {
        answerIndex: 1,
        label: function () { return i18n._('Augmenter'); },
    },
    101: {
        answerIndex: 2,
        label: function () { return i18n._('Soit baisser, soit augmenter ou rester stable'); },
    },
    102: {
        answerIndex: 0,
        label: function () { return i18n._('Une obligation libellée en EUR et disposant d\'un rating B+'); },
    },
    103: {
        answerIndex: 1,
        label: function () { return i18n._('Une obligation libellée en EUR et disposant d\'un rating A-'); },
    },
    104: {
        answerIndex: 2,
        label: function () { return i18n._('Peu importe, avec une obligation je suis sûr de récupérer le capital investi à l\'échéance'); },
    },
    105: {
        answerIndex: 0,
        label: function () { return i18n._('Elle est très risquée'); },
    },
    106: {
        answerIndex: 1,
        label: function () { return i18n._('Elle n\'est pas du tout risquée'); },
    },
    107: {
        answerIndex: 2,
        label: function () { return i18n._('Aucune agence de notation n\'a évalué la solvabilité de l\'émetteur'); },
    },
    108: {
        answerIndex: 0,
        label: function () { return i18n._('Évoluent généralement dans la même direction'); },
    },
    109: {
        answerIndex: 1,
        label: function () { return i18n._('Évoluent généralement dans des directions opposées'); },
    },
    110: {
        answerIndex: 2,
        label: function () { return i18n._('N\'ont aucune relation'); },
    },
    111: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    112: {
        answerIndex: 1,
        label: function () { return i18n._('Non'); },
    },
    113: {
        answerIndex: 2,
        label: function () { return i18n._('Parfois'); },
    },
    114: {
        answerIndex: 0,
        label: function () { return i18n._('Favorable'); },
    },
    115: {
        answerIndex: 1,
        label: function () { return i18n._('Défavorable'); },
    },
    116: {
        answerIndex: 2,
        label: function () { return i18n._('Sans incidence'); },
    },
    117: {
        answerIndex: 0,
        label: function () { return i18n._('Je ne suis pas sûr de pouvoir conserver mon placement jusqu\'à son terme'); },
    },
    118: {
        answerIndex: 1,
        label: function () { return i18n._('La taille de mon portefeuille est supérieure à 1 million d\'euros'); },
    },
    119: {
        answerIndex: 2,
        label: function () { return i18n._('L\'émetteur investit à l\'étranger'); },
    },
    120: {
        answerIndex: 0,
        label: function () { return i18n._('Gagné de l\'argent sur cette période'); },
    },
    121: {
        answerIndex: 1,
        label: function () { return i18n._('Perdu de l\'argent sur cette période'); },
    },
    122: {
        answerIndex: 2,
        label: function () { return i18n._('Ni gagné ni perdu d\'argent sur cette période'); },
    },
    123: {
        answerIndex: 0,
        label: function () { return i18n._('Oui'); },
    },
    124: {
        answerIndex: 1,
        label: function () { return i18n._('Non'); },
    },
    125: {
        answerIndex: 2,
        label: function () { return i18n._('Cela dépend du pays d\'émission'); },
    },
    126: {
        answerIndex: 0,
        label: function () { return i18n._('Une obligation'); },
    },
    127: {
        answerIndex: 1,
        label: function () { return i18n._('Une action'); },
    },
    128: {
        answerIndex: 2,
        label: function () { return i18n._('Un tracker'); },
    },
    129: {
        answerIndex: 0,
        label: function () { return i18n._('L\'obligation avec un rendement de 2%'); },
    },
    130: {
        answerIndex: 1,
        label: function () { return i18n._('L\'obligation avec un rendement de 4%'); },
    },
    131: {
        answerIndex: 2,
        label: function () { return i18n._('Cela dépend du coupon de ces deux obligations'); },
    },
    132: {
        answerIndex: 0,
        label: function () { return i18n._('De ne pas récupérer l\'intégralité du capital investi'); },
    },
    133: {
        answerIndex: 1,
        label: function () { return i18n._('De mettre l\'émetteur en difficulté'); },
    },
    134: {
        answerIndex: 2,
        label: function () { return i18n._('De faire l\'objet de poursuites judiciaires'); },
    },
    135: {
        answerIndex: 0,
        label: function () { return i18n._('La santé financière de l\'émetteur s\'est détériorée'); },
    },
    136: {
        answerIndex: 1,
        label: function () { return i18n._('La santé financière de l\'émetteur s\'est améliorée'); },
    },
    137: {
        answerIndex: 2,
        label: function () { return i18n._('La santé financière de l\'émetteur n\'a pas d\'impact sur le prix d\'une obligation'); },
    },
    138: {
        answerIndex: 0,
        label: function () { return i18n._('L\'obligation de l\'entreprise Alpha présente un risque de crédit supérieur à celui de l\'entreprise Delta'); },
    },
    139: {
        answerIndex: 1,
        label: function () { return i18n._('L\'obligation de l\'entreprise Alpha présente un risque de crédit inférieur à celui de l\'entreprise Delta'); },
    },
    140: {
        answerIndex: 2,
        label: function () { return i18n._('Les deux obligations ont le même niveau de risque'); },
    },
    141: {
        answerIndex: 0,
        label: function () { return i18n._('Un placement défensif'); },
    },
    142: {
        answerIndex: 1,
        label: function () { return i18n._('Un placement spéculatif'); },
    },
    143: {
        answerIndex: 2,
        label: function () { return i18n._('Un placement équilibré'); },
    },
    144: {
        answerIndex: 0,
        label: function () { return i18n._('L\'obligation avec une maturité de 1 an et un coupon de 3%'); },
    },
    145: {
        answerIndex: 1,
        label: function () { return i18n._('L\'obligation avec une maturité de 10 ans et un coupon de 3%'); },
    },
    146: {
        answerIndex: 2,
        label: function () { return i18n._('L\'obligation avec une maturité de 15 ans et un coupon de 3%'); },
    },
    147: {
        answerIndex: 0,
        label: function () { return i18n._('En Dollar américain'); },
    },
    148: {
        answerIndex: 1,
        label: function () { return i18n._('En Euro'); },
    },
    149: {
        answerIndex: 2,
        label: function () { return i18n._('En Dollar australien'); },
    },
    150: {
        answerIndex: 0,
        label: function () { return i18n._('1 EUR = 0,98 USD'); },
    },
    151: {
        answerIndex: 1,
        label: function () { return i18n._('1 EUR = 1,10 USD'); },
    },
    152: {
        answerIndex: 2,
        label: function () { return i18n._('1 EUR = 1,05 USD'); },
    },
    153: {
        answerIndex: 0,
        label: function () { return i18n._('Quand l\'échéance de l\'obligation est longue'); },
    },
    154: {
        answerIndex: 1,
        label: function () { return i18n._('Quand le coupon de l\'obligation est très faible'); },
    },
    155: {
        answerIndex: 2,
        label: function () { return i18n._('Quand il est difficile de pouvoir l\'acheter ou de la vendre sur le marché secondaire'); },
    },
    156: {
        answerIndex: 0,
        label: function () { return i18n._('Non, cela n\'a pas d\'importance'); },
    },
    157: {
        answerIndex: 1,
        label: function () { return i18n._('Oui, surtout si le prix d\'achat est supérieur au prix du call'); },
    },
    158: {
        answerIndex: 2,
        label: function () { return i18n._('Oui mais uniquement si l\'obligation arrive à échéance dans plus de 10 ans.'); },
    },
    159: {
        answerIndex: 0,
        label: function () { return i18n._('Au rating de la société ABC'); },
    },
    160: {
        answerIndex: 1,
        label: function () { return i18n._('À vérifier que la société à laquelle il accorde du crédit garantit bien l\'émission'); },
    },
    161: {
        answerIndex: 2,
        label: function () { return i18n._('Au cours du dollar US'); },
    },
    162: {
        answerIndex: 0,
        label: function () { return i18n._('En la vendant sur le marché secondaire'); },
    },
    163: {
        answerIndex: 1,
        label: function () { return i18n._('A l\'échéance'); },
    },
    164: {
        answerIndex: 2,
        label: function () { return i18n._('Jamais'); },
    },
    165: {
        answerIndex: 0,
        label: function () { return i18n._('Elle n\'a pas de coupon fixe'); },
    },
    166: {
        answerIndex: 1,
        label: function () { return i18n._('Elle n\'a pas d\'échéance fixe'); },
    },
    167: {
        answerIndex: 2,
        label: function () { return i18n._('Elle n\'a pas de rating'); },
    },
    168: {
        answerIndex: 0,
        label: function () { return i18n._('Plus de risque'); },
    },
    169: {
        answerIndex: 1,
        label: function () { return i18n._('Moins de risque'); },
    },
    170: {
        answerIndex: 2,
        label: function () { return i18n._('Le même niveau de risque'); },
    },
    171: {
        answerIndex: 0,
        label: function () { return i18n._('Parce qu\'elle n\'a pas d\'échéance et que son cours est généralement plus volatil'); },
    },
    172: {
        answerIndex: 1,
        label: function () { return i18n._('Parce qu\'elle n\'a pas d\'échéance et jamais de rating'); },
    },
    173: {
        answerIndex: 2,
        label: function () { return i18n._('Les obligations perpétuelles ne sont pas plus risquées que des obligations ordinaires'); },
    },
    174: {
        answerIndex: 0,
        label: function () { return i18n._('Une obligation dont l\'émetteur dispose de la faculté de suspendre momentanément le paiement d\'un coupon'); },
    },
    175: {
        answerIndex: 1,
        label: function () { return i18n._('Une obligation dont l\'émetteur dispose de la faculté d\'annuler purement et simplement le paiement d\'un coupon'); },
    },
    176: {
        answerIndex: 2,
        label: function () { return i18n._('Une obligation sans coupon'); },
    },
    177: {
        answerIndex: 0,
        label: function () { return i18n._('Ne pas bénéficier du coupon pendant plusieurs années'); },
    },
    178: {
        answerIndex: 1,
        label: function () { return i18n._('Devoir assumer le paiement du coupon auprès des autres créanciers si l\'émetteur est en difficulté'); },
    },
    179: {
        answerIndex: 2,
        label: function () { return i18n._('Voir son obligation remboursée si le coupon n\'est pas payé'); },
    },
    180: {
        answerIndex: 0,
        label: function () { return i18n._('Une obligation que son détenteur peut décider de transformer en action'); },
    },
    181: {
        answerIndex: 1,
        label: function () { return i18n._('Une obligation qui en cas de faillite de l\'émetteur n\'est remboursée qu\'après les obligations ordinaires'); },
    },
    182: {
        answerIndex: 2,
        label: function () { return i18n._('Une obligation qui en cas de faillite de l\'émetteur est remboursée avant les obligations ordinaires'); },
    },
    183: {
        answerIndex: 0,
        label: function () { return i18n._('Le détenteur d\'actions puis le détenteur d\'obligations subordonnées puis le détenteur d\'obligations senior'); },
    },
    184: {
        answerIndex: 1,
        label: function () { return i18n._('Le détenteur d\'obligations senior puis le détenteur d\'obligations subordonnées puis le détenteur d\'actions'); },
    },
    185: {
        answerIndex: 2,
        label: function () { return i18n._('Le détenteur d\'obligations subordonnées puis le détenteur d\'obligations senior puis le détenteur d\'actions'); },
    },
    186: {
        answerIndex: 0,
        label: function () { return i18n._('Le détenteur d\'une obligation senior sécurisée'); },
    },
    187: {
        answerIndex: 1,
        label: function () { return i18n._('Le détenteur d\'une obligation senior non sécurisée'); },
    },
    188: {
        answerIndex: 2,
        label: function () { return i18n._('Le détenteur d\'une obligation subordonnée'); },
    },
    189: {
        answerIndex: 0,
        label: function () { return i18n._('L\'obligation senior'); },
    },
    190: {
        answerIndex: 1,
        label: function () { return i18n._('L\'obligation subordonnée'); },
    },
    191: {
        answerIndex: 2,
        label: function () { return i18n._('L\'obligation senior sécurisée'); },
    },
    192: {
        answerIndex: 0,
        label: function () { return i18n._('C\'est un produit financier qui a pour but de répliquer un indice de référence'); },
    },
    193: {
        answerIndex: 1,
        label: function () { return i18n._('C\'est un produit financier qui a fait par le passé de très bonnes performances'); },
    },
    194: {
        answerIndex: 2,
        label: function () { return i18n._('C\'est un produit financier qui a pour but de garantir le capital de l\'investisseur'); },
    },
    195: {
        answerIndex: 0,
        label: function () { return i18n._('Une action d\'entreprise'); },
    },
    196: {
        answerIndex: 1,
        label: function () { return i18n._('Une obligation d\'entreprise'); },
    },
    197: {
        answerIndex: 2,
        label: function () { return i18n._('Un tracker'); },
    },
    198: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, mais il est possible que je doive vendre à un cours inférieur au capital investi'); },
    },
    199: {
        answerIndex: 1,
        label: function () { return i18n._('Pour des raisons fiscales, il vaut mieux rester investi au moins 8 ans'); },
    },
    200: {
        answerIndex: 2,
        label: function () { return i18n._('Non, il est impossible de vendre une tracker à court terme'); },
    },
    201: {
        answerIndex: 0,
        label: function () { return i18n._('Sur le marché de gré à gré'); },
    },
    202: {
        answerIndex: 1,
        label: function () { return i18n._('En bourse'); },
    },
    203: {
        answerIndex: 2,
        label: function () { return i18n._('Les parts de trackers sont achetées et vendues chez l\'émetteur du produit'); },
    },
    204: {
        answerIndex: 0,
        label: function () { return i18n._('Oui toujours'); },
    },
    205: {
        answerIndex: 1,
        label: function () { return i18n._('Non jamais'); },
    },
    206: {
        answerIndex: 2,
        label: function () { return i18n._('Cela dépend des trackers'); },
    },
    207: {
        answerIndex: 0,
        label: function () { return i18n._('Un tracker qui investit directement dans les instruments financiers ou l\'indice de référence dont il entend répliquer la performance'); },
    },
    208: {
        answerIndex: 1,
        label: function () { return i18n._('Un tracker qui n\'investit pas lui-même dans les actifs qui composent son indice de référence mais plutôt dans des produits dérivés structurés de manière à répliquer la performance de l\'indice concerné'); },
    },
    209: {
        answerIndex: 2,
        label: function () { return i18n._('Il s\'agit d\'un tracker qui n\'investit que dans des obligations convertibles'); },
    },
    210: {
        answerIndex: 0,
        label: function () { return i18n._('Oui, les trackers synthétiques présentent un risque supplémentaire de contrepartie'); },
    },
    211: {
        answerIndex: 1,
        label: function () { return i18n._('Non, il s\'agit en principe du même risque'); },
    },
    212: {
        answerIndex: 2,
        label: function () { return i18n._('Non, les trackers synthétiques présentent moins de risque que les trackers qui investissent directement dans les actifs composant leur indice de référence'); },
    },
    213: {
        answerIndex: 0,
        label: function () { return i18n._('Les trackers synthétiques'); },
    },
    214: {
        answerIndex: 1,
        label: function () { return i18n._('Les trackers qui investissent directement dans des actifs qui composent leur indice'); },
    },
    215: {
        answerIndex: 2,
        label: function () { return i18n._('Il est le même pour tous les types de trackers'); },
    },
    216: {
        answerIndex: 0,
        label: function () { return i18n._('Le risque principal est lié à la santé financière de l\'émetteur'); },
    },
    217: {
        answerIndex: 1,
        label: function () { return i18n._('Le risque principal est celui lié à l\'évolution du prix des matières premières sur lesquelles portent le tracker'); },
    },
    218: {
        answerIndex: 2,
        label: function () { return i18n._('Le risque principal est lié à la solvabilité des entreprises actives dans le secteur des matières premières'); },
    },
    219: {
        answerIndex: 0,
        label: function () { return i18n._('Il va monter'); },
    },
    220: {
        answerIndex: 1,
        label: function () { return i18n._('Il va diminuer'); },
    },
    221: {
        answerIndex: 2,
        label: function () { return i18n._('Il va rester stable'); },
    },
    222: {
        answerIndex: 0,
        label: function () { return i18n._('Car le prix des matières premières est particulièrement volatil'); },
    },
    223: {
        answerIndex: 1,
        label: function () { return i18n._('Parce qu\'ils sont émis par des sociétés fortement endettées'); },
    },
    224: {
        answerIndex: 2,
        label: function () { return i18n._('Parce qu\'il s\'agit de produit dans lequel l\'investisseur est bloqué pendant plusieurs années'); },
    },
    225: {
        answerIndex: 0,
        label: function () { return i18n._('Plus'); },
    },
    226: {
        answerIndex: 1,
        label: function () { return i18n._('Moins'); },
    },
    227: {
        answerIndex: 2,
        label: function () { return i18n._('Autant l\'un que l\'autre'); },
    },
};
export var useAnswerLabelByQuestionIdAndPrimaryKey = function (questionId, primaryKey) {
    var potentialAnswerId = React.useMemo(function () { return getPotentialAnswerIdByQuestionIdAndAnswerIndex(data.questionnairePotentialAnswers, questionId, typeof primaryKey === 'string' ? parseInt(primaryKey, 10) : primaryKey); }, [
        data.questionnairePotentialAnswers,
        questionId,
        primaryKey,
    ]);
    var translated = answerLabels[potentialAnswerId].label();
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
export var AnswerLabel = function (props) {
    var questionId = props.questionId, primaryKey = props.primaryKey;
    var translated = useAnswerLabelByQuestionIdAndPrimaryKey(questionId, primaryKey);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, translated);
};
